import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { authenticateClinician } from '../Api'
import { SET_USER_IS_LOGGED_IN } from '../Constants/user'
import { UserContext } from '../context'
import { getErrorRouteFromErrorCode, trackEvent } from '../utils'

const useAuthenticateClinician = (grandIDSession, verificationMethod) => {
  const navigate = useNavigate()

  const { dispatch } = useContext(UserContext)

  useEffect(() => {
    if (!grandIDSession || !verificationMethod) {
      return navigate('/')
    }

    const abortController = new AbortController()
    const handleAuthenticateClinician = async () => {
      let response
      try {
        response = await authenticateClinician(
          grandIDSession,
          verificationMethod,
          abortController.signal,
        )
      } catch (e) {
        if (e.name === 'AbortError') {
          // Do not call navigate() if an AbortError has been caught.
          // That would abort the request from the component which
          // is currently calling authenticateClinician().
          return
        }
        return navigate('/')
      }

      const { success, userCreated, errorCode } = response

      if (success) {
        dispatch({ type: SET_USER_IS_LOGGED_IN })
        trackEvent('User logged in')
        if (userCreated) {
          trackEvent('User account created')
        }
        return navigate('/')
      } else {
        const errorRoute = getErrorRouteFromErrorCode(errorCode)
        return navigate(errorRoute)
      }
    }

    handleAuthenticateClinician()

    return () => {
      abortController.abort()
    }
  }, [grandIDSession, verificationMethod, dispatch, navigate])
}

export default useAuthenticateClinician
