import useTooltips from '../../../hooks/useTooltips'

import { ResultsText } from '../../../texts'

export const CustomTick = (props) => {
  const { payload, x, y, dy, textAnchor, fill, index } = props
  const { showTooltipRef } = useTooltips()

  // Show the tick values as severity levels instead of cutoff values
  const levelDisplayValue = payload.value - 0.5
  const id = `custom-tick-${levelDisplayValue}`
  const tooltip = `${ResultsText.level} ${levelDisplayValue}: ${ResultsText.diagnosisSeverityLabelsDescription[levelDisplayValue]}`
  const style = { fill, fontSize: '12px' }
  return (
    <text
      x={x}
      y={y}
      textAnchor={textAnchor}
      id={id}
      data-tooltip={tooltip}
      ref={showTooltipRef}
    >
      <tspan x={x} dy={dy} style={style} key={index}>
        {levelDisplayValue}
      </tspan>
    </text>
  )
}
