import { Icon } from '../Icon'
import Styles from './button.module.scss'

export const ButtonStyle = {
  Primary: 'primary',
  Secondary: 'secondary',
  IconSmall: 'iconsmall',
}

export const ButtonIcon = {
  ArrowBack: 'arrowBack',
  Cross: 'cross',
}

export const Button = (props) => {
  const {
    children,
    onClick,
    options = { style: ButtonStyle.Primary, icon: null },
    ariaLabel,
  } = props
  const { style = ButtonStyle.Primary, icon = null } = options

  return (
    <button className={Styles[style]} onClick={onClick} aria-label={ariaLabel}>
      {icon && <Icon iconType={icon} />}
      {children}
    </button>
  )
}
