import classNames from 'classnames'
import React from 'react'

import Styles from './icon.module.scss'
import { Icons } from './icons'

export const Icon = (props) => {
  const { className, iconType } = props

  const iconWrapperClassNames = classNames(Styles.iconWrapper, className)

  return <span className={iconWrapperClassNames}>{Icons[iconType]}</span>
}
