import { useNavigate } from 'react-router-dom'

import { useLogout } from '../../hooks/useLogout'

import { MenuBarText } from '../../texts'
import { MenuButton } from '../MenuButton'
import logoutIconSrc from './logouticon.png'
import './menuBar.scss'
import patientListIconSrc from './patientlisticon.png'

export const MenuBar = () => {
  const navigate = useNavigate()
  const { handleLogout } = useLogout()

  const handleClickPatientList = () => {
    navigate('/')
  }

  return (
    <div className="bar">
      <div className="topSection">
        <MenuButton onClick={handleClickPatientList}>
          <img src={patientListIconSrc} alt={MenuBarText.altPatientList} />
        </MenuButton>
      </div>
      <div className="bottomSection">
        <MenuButton onClick={handleLogout}>
          <img src={logoutIconSrc} alt={MenuBarText.altLogout} />
        </MenuButton>
      </div>
    </div>
  )
}
