import { useContext, useEffect, useState } from 'react'

import { getPatients } from '../Api'
import { ResponseErrorCode, ResponseState } from '../Constants/enums'
import {
  LOG_OUT,
  SET_CLINIC_NAME,
  SET_PATIENTS,
  SET_SEARCH_RESULTS,
} from '../Constants/user'
import { UserContext } from '../context'

const useGetPatients = () => {
  const [responseStateGetPatientsList, setResponseStateGetPatientsList] =
    useState(ResponseState.NotSent)

  const [
    responseErrorCodeGetPatientsList,
    setResponseErrorCodeGetPatientsList,
  ] = useState(null)

  const { dispatch } = useContext(UserContext)

  useEffect(() => {
    if (responseStateGetPatientsList !== ResponseState.NotSent) return

    setResponseStateGetPatientsList(ResponseState.Pending)
    dispatch({ type: SET_SEARCH_RESULTS, searchResults: [] })

    const handleGetPatientsList = async () => {
      try {
        const { success, patients, errorCode, clinicName } = await getPatients()

        if (success) {
          setResponseStateGetPatientsList(ResponseState.Success)
          dispatch({ type: SET_CLINIC_NAME, clinicName })
          dispatch({ type: SET_PATIENTS, patients })
          return
        }
        if (errorCode === ResponseErrorCode.NotAuthenticated) {
          setResponseStateGetPatientsList(ResponseState.Failure)
          return dispatch({ type: LOG_OUT })
        }

        setResponseStateGetPatientsList(ResponseState.Failure)
        setResponseErrorCodeGetPatientsList(errorCode)
      } catch {
        setResponseStateGetPatientsList(ResponseState.Failure)
      }
    }

    handleGetPatientsList()
  }, [dispatch, responseStateGetPatientsList])

  return {
    responseStateGetPatientsList,
    responseErrorCodeGetPatientsList,
  }
}

export default useGetPatients
