import { useState } from 'react'

import { initiateGrandIDVerification } from '../Api'
import { ResponseState } from '../Constants/enums'

const VerificationMethod = {
  SITHS: 'siths',
  BANKID: 'bankid',
}

const useInitiateGrandIDVerification = () => {
  const [responseStateInitVerification, setResponseStateInitVerification] =
    useState(ResponseState.NotSent)

  const handleInitiateGrandIDVerification = async (verificationMethod) => {
    setResponseStateInitVerification(ResponseState.Pending)

    try {
      const response = await initiateGrandIDVerification(verificationMethod)

      const { success, redirectUrl } = response

      if (success) {
        setResponseStateInitVerification(ResponseState.Success)
        window.location.assign(redirectUrl)
      } else {
        setResponseStateInitVerification(ResponseState.Failure)
      }
    } catch (e) {
      setResponseStateInitVerification(ResponseState.Failure)
      return
    }
  }

  const handleInitiateSITHSVerification = () => {
    handleInitiateGrandIDVerification(VerificationMethod.SITHS)
  }

  const handleInitiateBankIDVerification = () => {
    handleInitiateGrandIDVerification(VerificationMethod.BANKID)
  }

  return {
    handleInitiateSITHSVerification,
    handleInitiateBankIDVerification,
    responseStateInitVerification,
  }
}

export default useInitiateGrandIDVerification
