import useTooltips from '../../hooks/useTooltips'

import { ResultsText } from '../../texts'
import { getColorFromSeverityLevel } from '../../utils'
import Styles from './severityOverview.module.scss'

export const SeverityOverview = (props) => {
  const { severityLevels, rowId } = props

  if (severityLevels === undefined) {
    return
  }

  let severityLevelsColor = {}

  Object.entries(severityLevels).forEach(([area, severity]) => {
    const areaColor = getColorFromSeverityLevel(severity)
    severityLevelsColor[area] = areaColor
  })

  const SeverityLevelsIcons = {
    MDD: {
      title: `${ResultsText.MDD}
      ${ResultsText.diagnosisSeverityLabels[severityLevels.MDD]}`,
      className: Styles[severityLevelsColor.MDD],
      svgElement: (
        <path d="M8.13398 1.15796C8.51888 0.491292 9.48112 0.491292 9.86602 1.15796L17.6603 14.658C18.0452 15.3246 17.564 16.158 16.7942 16.158H1.20577C0.435971 16.158 -0.0451542 15.3246 0.339746 14.658L8.13398 1.15796Z" />
      ),
      svgProp: { width: '18', height: '17', viewBox: '0 0 18 17' },
    },
    SED: {
      title: `${ResultsText.SED}
      ${ResultsText.diagnosisSeverityLabels[severityLevels.SED]}`,
      className: Styles[severityLevelsColor.SED],
      svgElement: <rect width="16" height="16" rx="3" />,
      svgProp: { width: '16', height: '16', viewBox: '0 0 16 16' },
    },
    GAD: {
      title: `${ResultsText.GAD}
      ${ResultsText.diagnosisSeverityLabels[severityLevels.GAD]}`,
      className: Styles[severityLevelsColor.GAD],
      svgElement: <rect width="18" height="18" rx="9" />,
      svgProp: { width: '18', height: '18', viewBox: '0 0 18 18' },
    },
  }

  const SVGIcon = (props) => {
    const { showTooltipRef } = useTooltips()
    const id = `${props.type}-${props.rowId}`
    return (
      <svg
        id={id}
        className={props.className}
        width={props.svgProp.width}
        height={props.svgProp.height}
        viewBox={props.svgProp.viewBox}
        xmlns="http://www.w3.org/2000/svg"
        data-tooltip={props.title}
        data-testid={id}
        ref={showTooltipRef}
      >
        {props.children}
      </svg>
    )
  }

  return (
    <div className={Styles.severityOverviewContainer}>
      {Object.entries(SeverityLevelsIcons).map(([type, iconProp]) => {
        return (
          <SVGIcon key={type} {...iconProp} type={type} rowId={rowId}>
            {iconProp.svgElement}
          </SVGIcon>
        )
      })}
    </div>
  )
}
