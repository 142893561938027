import {
  CLEAR_NOTIFICATION,
  LOG_OUT,
  RESET_CURRENT_PATIENT_DATA,
  SET_CLINIC_NAME,
  SET_CURRENT_PATIENT,
  SET_CURRENT_PATIENT_ACCESSES,
  SET_CURRENT_PATIENT_ANSWERS,
  SET_CURRENT_PATIENT_RESULTS,
  SET_NOTIFICATION,
  SET_PATIENTS,
  SET_SEARCH_RESULTS,
  SET_USER_DATA,
  SET_USER_IS_LOGGED_IN,
} from '../Constants/user'

export const userInitalState = {
  isLoggedIn: false,
  notification: '',
  currentPatient: {
    id: '',
    firstName: '',
    lastName: '',
    answers: [],
    results: [],
    accesses: [],
  },
  clinicName: '',
  patients: [],
  searchResults: [],
}

const reducer = (state, action) => {
  const { type, ...rest } = action
  switch (type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...rest,
      }
    }
    case SET_USER_IS_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: true,
      }
    }
    case LOG_OUT: {
      const { notification } = state
      return { ...userInitalState, notification }
    }
    case SET_PATIENTS: {
      const { patients } = action
      return {
        ...state,
        patients,
      }
    }
    case SET_SEARCH_RESULTS: {
      const { searchResults } = action
      return {
        ...state,
        searchResults,
      }
    }
    case SET_CURRENT_PATIENT: {
      const { currentPatient } = action
      return {
        ...state,
        currentPatient,
      }
    }
    case RESET_CURRENT_PATIENT_DATA: {
      return {
        ...state,
        currentPatient: userInitalState.currentPatient,
      }
    }
    case SET_CURRENT_PATIENT_RESULTS: {
      const { results } = action
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          results,
        },
      }
    }
    case SET_CURRENT_PATIENT_ANSWERS: {
      const { answers } = action

      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          answers,
        },
      }
    }
    case SET_CURRENT_PATIENT_ACCESSES: {
      const { accesses } = action
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          accesses,
        },
      }
    }
    case SET_CLINIC_NAME: {
      const { clinicName } = action
      return {
        ...state,
        clinicName,
      }
    }
    case SET_NOTIFICATION: {
      const { notification } = action
      return {
        ...state,
        notification,
      }
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        notification: '',
      }
    }
    default: {
      return state
    }
  }
}
export default reducer
