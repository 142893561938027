export const transformGetPatientAccesses = (response) => {
  const { success, accesses: rawAccesses, error_code: errorCode } = response

  const accesses =
    rawAccesses &&
    rawAccesses.map((access) => {
      return {
        clinicianId: access.clinician_id,
        clinicianName: access.clinician_name,
        accessDate: access.access_date,
      }
    })

  return { success, accesses, errorCode }
}
