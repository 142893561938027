import {
  AssessmentType,
  ErrorPath,
  NotificationType,
  ONBOARDING_TYPE,
} from './Constants/enums'

export const General = {
  loading: 'Laddar...',
}

export const ButtonText = {
  back: 'Tillbaka',
  close: 'Stäng',
  logIn: 'Logga in',
  logOut: 'Logga ut',
  logInWithSITHS: 'Logga in med SITHS',
  logInWithBankID: 'Logga in med BankID',
  stayLoggedIn: 'Fortsätt vara inloggad',
}

export const WelcomeText = {
  heading: 'Logga in på ditt konto',
  paragraph1:
    'Välkommen till Wise, en produkt från [Ablemind](https://ablemind.co).',
  paragraph2:
    'Har du stött på problem? [Kontakta oss](mailto:support@ablemind.co).',
  requestError: 'Problem med autentisering, var god försök igen.',
}

export const MainText = {
  patientList: 'Patientlista',
  patientListSubHeading: 'Klicka på en patient för att se resultat',
  logoutError: 'Fel vid utloggning, försök igen.',
  getPatientsListError: 'Det gick inte att hämta patientlistan.',
}

export const VerifyText = {
  pleaseWait: 'Var god vänta.',
  authenticationInProgress: 'Authentisering pågår.',
}

export const ResultsText = {
  heading: 'Patientresultat',
  subHeading: 'Överblick av patientresultat för',
  loading: 'Läser in resultat',
  loadingFailed: 'Problem vid inläsning av resultat',
  noResultsForPatient: 'Den här patienten har inga resultat än',
  date: 'Datum',
  MDD: 'Egentlig depression',
  GAD: 'Generaliserat ångestsyndrom',
  SED: 'Utmattningssyndrom',
  increased: 'ökat',
  noChange: 'oförändrat',
  decreased: 'minskat',
  comparedToLastResult: 'vs senaste resultat',
  readMore: 'Läs mer',

  latestResult: 'Senaste Resultat',
  generalHealth: 'Generellt mående',

  answersHeading: 'Patientens beskrivning av sitt mående',
  allAnswersToQuestionHowAreYou: 'Patientens alla svar på frågan "Hur mår du?"',
  answerToQuestionHowAreYou: 'Patientens svar på frågan "Hur mår du?"',
  severity: 'Svårighetsgrad',

  diagnosisSeverityLabels: {
    1: 'Ingen diagnos',
    2: 'Subklinisk',
    3: 'Mild',
    4: 'Måttlig',
    5: 'Medelsvår',
    6: 'Svår',
    7: 'Extremt svår',
  },

  resultOverTimeHeading: 'Resultat över tid',
  resultOverTimeParagraph1:
    'Svårighetsgrad av diagnos över tid. Svårighetsgrad visas ut i skala 1-7.',
  resultOverTimeParagraph2:
    'Skalan visar både omfattning av symptom och symtomens påverkan på individens funktion i viktiga delar av livet.',

  diagnosisSeverityLabelsDescription: {
    1: 'Inga symptom eller tecken på diagnos. Ingen påverkan på funktion.',
    2: 'Milda, ej fullt utvecklade, symptom och tecken på diagnos. Minimal påverkan på funktion.',
    3: 'Milda, tydligt manifesterade symptom, som uppfyller diagnostiska kriterier för diagnos. Mild, klinisk signifikant försämrad funktion.',
    4: 'Måttliga, tydligt manifesterade symptom. Måttlig påverkan på individens funktionsnivå.',
    5: 'Medelsvåra, besvärande och begränsande symptom som tydligt sänker individens funktion.',
    6: 'Svåra, tydligt besvärande och begränsade symptom som innebär en omfattande och svår begränsning i funktion.',
    7: 'Extremt svåra symptom och problematik, som starkt begränsar och markant försämrar individens funktion.',
  },
  level: 'Grad',
}

export const AccessSummaryText = {
  others: 'andra',
  numericSummary: 'och {{link}} har tittat på patientens resultat.',
  buttonAltText: 'Öppna lista med besökare',
}

export const AccessLogModalTexts = {
  title: 'Användare som tittat på {{firstName}} {{lastName}}s resultat',
}

export const PatientsListText = {
  name: 'Namn',
  nationalID: 'Personnummer',
  patientsListEmpty: 'Du har inga listade patienter.',
  latestResult: ResultsText.latestResult,
  [AssessmentType.Pre]: 'Före behandling',
  [AssessmentType.During]: 'Under behandling',
  [AssessmentType.Post]: 'Avslutande mätning',
  [AssessmentType.FollowUp]: 'Uppföljande mätning',
}

export const MenuBarText = {
  altPatientList: 'Knapp för att komma till Patientlistan',
  altLogout: 'Knapp för att Logga ut',
}

export const typeofsurveyAnswerLabels = {
  pre: 'Första besök',
  during: 'Under behandling',
  post: 'Sista besök',
  followUp: 'Uppföljande mätning',
}

export const searchText = {
  placeholder: 'Sök patient',
  feedback: '{{numberOfMatches}} resultat för "{{searchTerm}}"',
  show: 'visa',
  clear: 'tillbaka till listan',
}

export const onboardingImageAlt = {
  [ONBOARDING_TYPE.ACTIVATING]: {
    1: 'Ditt konto håller som bäst på att aktiveras. För att värna om patientsäkerhet sker en manuell verifiering vilket kan ta upp till 24 h.',
    2: 'Patientlistan',
    3: 'Senaste resultat',
    4: 'Resultat över tid',
    5: 'Tidigare svar',
  },
  [ONBOARDING_TYPE.WAITING]: {
    1: 'Vi väntar på svar från dina patienter. När vi fått in svar från en patient kommer du att kunna se resultaten här.',
    2: 'Patientlistan',
    3: 'Senaste resultat',
    4: 'Resultat över tid',
    5: 'Tidigare svar',
  },
}

export const notificationText = {
  [NotificationType.IdleTimeoutWarning]: {
    title: 'Är du fortfarande här?',
    texts: [
      'Du kommer snart att loggas ut automatiskt på grund av inaktivitet. Välj om du vill fortsätta vara inloggad eller logga ut direkt.',
    ],
  },
  [NotificationType.IdleTimeoutLogout]: {
    title: 'Du har blivit utloggad',
    texts: [
      'Du har varit inaktiv i 15 minuter och har därför loggats ut på grund av säkerhetsskäl.',
    ],
  },
  severityInfo: {
    texts: [
      'Samtliga diagnoser analyseras enligt Socialstyrelsens rekommenderade *gold standard* för utvärdering av psykiska hälsotillstånd.',
      'Diagnos för **egentlig depression** och **generaliserat ångestsyndrom** baseras på bedömningsdata från seniora kliniska experter (LEAD-bedömare) som utgår från tillståndets diagnostiska kriterier (Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition, DSM-5). Diagnos för **utmattningssyndrom** baseras på Socialstyrelsens kriterier (Socialstyrelsen, 2013).',
    ],
  },
}

export const errorText = {
  [ErrorPath.DefaultError]: {
    preHeading: 'Oops',
    heading: 'Något gick fel',
    paragraph: 'Vänligen försök igen eller kontakta support@ablemind.co',
  },
  [ErrorPath.AccessDenied]: {
    preHeading: 'Åtkomst nekad',
    heading: 'Tyvärr, du har inte behörighet att skapa konto',
    paragraph: 'Vänligen kontakta din administratör eller support@ablemind.co',
  },
  [ErrorPath.PageNotFound]: {
    preHeading: 'Sidan hittades inte',
    heading: 'Något gick fel',
    paragraph: 'Vänligen försök igen eller kontakta support@ablemind.co',
  },
}
