import classNames from 'classnames'

import { Color, Progression } from '../../Constants/enums'
import SeverityAccents from '../../styles/_severityColors.module.scss'
import { ResultsText } from '../../texts'
import { getColorFromSeverityLevel } from '../../utils'
import arrowDownIcon from './arrowDown.svg'
import arrowRightIcon from './arrowRight.svg'
import arrowUpIcon from './arrowUp.svg'
import Styles from './diagnosisCard.module.scss'

export const DiagnosisCard = (props) => {
  const { label, diagnosisSeverityLevel, comparedToPrevious } = props
  const severityAccentColor = getColorFromSeverityLevel(diagnosisSeverityLevel)

  const bgColorAccentClass = {
    [Color.Green]: SeverityAccents.greenBg,
    [Color.Yellow]: SeverityAccents.yellowBg,
    [Color.Red]: SeverityAccents.redBg,
  }

  const fgColorAccentClass = {
    [Color.Green]: SeverityAccents.greenFg,
    [Color.Yellow]: SeverityAccents.yellowFg,
    [Color.Red]: SeverityAccents.redFg,
  }

  const iconClassOverride = {
    [Progression.Increased]: Styles.hasChanged,
    [Progression.Decreased]: Styles.hasChanged,
  }

  const cardClasses = classNames(
    Styles.diagnosisCard,
    bgColorAccentClass[severityAccentColor],
  )

  const severityClasses = classNames(
    Styles.severity,
    fgColorAccentClass[severityAccentColor],
  )

  const iconClasses = classNames(
    Styles.icon,
    iconClassOverride[comparedToPrevious],
  )

  const comparisonArrow = {
    [Progression.Increased]: arrowUpIcon,
    [Progression.NoChange]: arrowRightIcon,
    [Progression.Decreased]: arrowDownIcon,
  }

  return (
    <div className={cardClasses}>
      <div className={Styles.label}>{label}</div>
      <div className={severityClasses}>
        {ResultsText.diagnosisSeverityLabels[diagnosisSeverityLevel]}
      </div>
      {comparedToPrevious && (
        <div className={Styles.comparison}>
          <div className={iconClasses}>
            <img src={comparisonArrow[comparedToPrevious]} alt="" />
          </div>
          <div className={Styles.label}>
            <span className={Styles.bold}>
              {ResultsText[comparedToPrevious]}
            </span>{' '}
            {ResultsText.comparedToLastResult}
          </div>
        </div>
      )}
    </div>
  )
}
