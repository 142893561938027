import { Modal } from '../../Components/Modal'

import { ButtonText, notificationText } from '../../texts'
import { ButtonStyle } from '../Button'

export const SeverityInfoModal = (props) => {
  const { show, handleClose } = props

  const buttons = [
    {
      style: ButtonStyle.Secondary,
      label: ButtonText.close,
      action: handleClose,
    },
  ]

  return (
    <Modal
      buttons={buttons}
      show={show}
      handleClose={handleClose}
      texts={notificationText.severityInfo.texts}
    />
  )
}

export default SeverityInfoModal
