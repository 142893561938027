import { transformGetPatientAccesses } from '../transformers/transformGetPatientAccesses'
import { transformGetPatientsResponse } from '../transformers/transformGetPatientsResponse'
import { getCookieValue } from '../utils'

export const origin = process.env.REACT_APP_API_ORIGIN

export const initiateGrandIDVerificationEndpoint =
  'auth/initiategrandidverification/'
export const authenticateClinicianEndpoint = 'auth/authenticateclinician/'
export const logoutClinicianEndpoint = 'auth/logout/'
export const getPatientsEndpoint = 'getpatients/'
export const getPatientResultsEndpoint = 'getpatientresults/'
export const getPatientAnswersEndpoint = 'getpatientanswers/'
export const getPatientAccessesEndpoint = 'getpatientaccesses/'
const CSRF_TOKEN_NAME = 'csrftoken-wise'

export const initiateGrandIDVerification = async (verificationMethod) => {
  try {
    const csrftoken = getCookieValue(CSRF_TOKEN_NAME)

    const result = await fetch(
      `${origin}${initiateGrandIDVerificationEndpoint}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ verification_method: verificationMethod }),
      },
    )

    const response = await result.json()

    const { success, redirect_url: redirectUrl } = response

    return { success, redirectUrl }
  } catch {
    return { success: false }
  }
}

export const authenticateClinician = async (
  grandIDSession,
  verificationMethod,
  signal,
) => {
  const csrftoken = getCookieValue(CSRF_TOKEN_NAME)

  const result = await fetch(`${origin}${authenticateClinicianEndpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    credentials: 'include',
    body: JSON.stringify({
      grand_id_session: grandIDSession,
      verification_method: verificationMethod,
    }),
    signal,
  })

  const response = await result.json()

  const {
    message,
    success,
    user_created: userCreated,
    error_code: errorCode,
  } = response

  return { message, success, userCreated, errorCode }
}

export const logoutClinician = async () => {
  try {
    const csrftoken = getCookieValue(CSRF_TOKEN_NAME)

    const result = await fetch(`${origin}${logoutClinicianEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    })

    return await result.json()
  } catch {
    return { success: false }
  }
}

export const getPatients = async () => {
  try {
    const csrftoken = getCookieValue(CSRF_TOKEN_NAME)

    const result = await fetch(`${origin}${getPatientsEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    })

    const response = await result.json()

    const transformedResponse = transformGetPatientsResponse(response)

    return transformedResponse
  } catch {
    return { success: false }
  }
}

export const getPatientResults = async (patientId) => {
  try {
    const csrftoken = getCookieValue(CSRF_TOKEN_NAME)

    const result = await fetch(`${origin}${getPatientResultsEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
      body: JSON.stringify({ id: patientId }),
    })

    const response = await result.json()

    const { success, results: patientResults, error_code: errorCode } = response

    return { success, patientResults, errorCode }
  } catch {
    return { success: false }
  }
}

export const getPatientAnswers = async (patientId) => {
  try {
    const csrftoken = getCookieValue(CSRF_TOKEN_NAME)

    const result = await fetch(`${origin}${getPatientAnswersEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
      body: JSON.stringify({ id: patientId }),
    })

    const response = await result.json()

    const { success, answers: patientAnswers, error_code: errorCode } = response

    return { success, patientAnswers, errorCode }
  } catch {
    return { success: false }
  }
}

export const getPatientAccesses = async (patientId) => {
  try {
    const csrftoken = getCookieValue(CSRF_TOKEN_NAME)

    const result = await fetch(`${origin}${getPatientAccessesEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
      body: JSON.stringify({ id: patientId }),
    })

    const response = await result.json()

    const transformedResponse = transformGetPatientAccesses(response)

    return transformedResponse
  } catch {
    return { success: false }
  }
}
