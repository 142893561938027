export const InsertJsxInString = (props) => {
  const { string, replace, element } = props
  const [before, after] = string.split(replace)

  return (
    <>
      {before}
      {element}
      {after}
    </>
  )
}
