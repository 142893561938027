import { useContext, useEffect, useState } from 'react'

import { SET_SEARCH_RESULTS } from '../Constants/user'
import { UserContext } from '../context'

const usePatientFilter = (searchTerm) => {
  const [preliminarySearchResults, setPreliminarySearchResults] = useState([])

  const {
    state: { patients },
    dispatch,
  } = useContext(UserContext)

  const matchWord = (matchString, searchPart) => {
    return matchString.toLowerCase().includes(searchPart.toLowerCase())
  }

  const getSearchTermArray = (searchTerm) => {
    const filterLettersNumbers = /[^\p{L}\p{N}]+/gu
    return searchTerm
      .split(' ')
      .map((searchPart) => searchPart.replace(filterLettersNumbers, ''))
      .filter((searchPart) => searchPart !== '')
  }

  useEffect(() => {
    if (!searchTerm) return setPreliminarySearchResults([])
    const searchTermArray = getSearchTermArray(searchTerm)
    const results = patients.filter((patient) => {
      const { firstName, lastName, nationalID } = patient
      const matchString = `${firstName} ${lastName} ${nationalID}`
      return searchTermArray.every((searchPart) =>
        matchWord(matchString, searchPart),
      )
    })
    setPreliminarySearchResults(results)
  }, [patients, searchTerm])

  const showSearchResults = () => {
    dispatch({
      type: SET_SEARCH_RESULTS,
      searchResults: preliminarySearchResults,
    })
  }

  const clearSearchResults = () => {
    dispatch({ type: SET_SEARCH_RESULTS, searchResults: [] })
  }

  return {
    preliminarySearchResults,
    showSearchResults,
    clearSearchResults,
  }
}

export default usePatientFilter
