import { useContext, useEffect, useState } from 'react'

import {
  getPatientAccesses,
  getPatientAnswers,
  getPatientResults,
} from '../Api'
import { ResponseErrorCode, ResponseState } from '../Constants/enums'
import {
  LOG_OUT,
  SET_CURRENT_PATIENT_ACCESSES,
  SET_CURRENT_PATIENT_ANSWERS,
  SET_CURRENT_PATIENT_RESULTS,
} from '../Constants/user'
import { UserContext } from '../context'

const useGetPatientData = () => {
  const [resultsResponseState, setResultsResponseState] = useState(
    ResponseState.NotSent,
  )
  const [answersResponseState, setAnswersResponseState] = useState(
    ResponseState.NotSent,
  )
  const [accessesResponseState, setAccessesResponseState] = useState(
    ResponseState.NotSent,
  )
  const [dataResponseState, setDataResponseState] = useState(
    ResponseState.NotSent,
  )

  const {
    dispatch,
    state: {
      currentPatient: { id: currentPatientId },
    },
  } = useContext(UserContext)

  useEffect(() => {
    if (resultsResponseState !== ResponseState.NotSent) return
    if (answersResponseState !== ResponseState.NotSent) return
    if (!currentPatientId) {
      return
    }

    const handleGetPatientResults = async () => {
      setResultsResponseState(ResponseState.Pending)

      dispatch({
        type: SET_CURRENT_PATIENT_RESULTS,
        currentPatientResults: [],
      })

      try {
        const response = await getPatientResults(currentPatientId)

        const { success, patientResults } = response

        if (success) {
          dispatch({
            type: SET_CURRENT_PATIENT_RESULTS,
            results: patientResults,
          })
          setResultsResponseState(ResponseState.Success)
          return
        }
        setResultsResponseState(ResponseState.Failure)
        const { errorCode } = response
        if (errorCode === ResponseErrorCode.NotAuthenticated) {
          dispatch({ type: LOG_OUT })
        }
      } catch {
        setResultsResponseState(ResponseState.Failure)
      }
    }

    const handleGetPatientAnswers = async () => {
      setAnswersResponseState(ResponseState.Pending)

      dispatch({
        type: SET_CURRENT_PATIENT_ANSWERS,
        currentPatientResults: [],
      })

      try {
        const response = await getPatientAnswers(currentPatientId)

        const { success, patientAnswers } = response

        if (success) {
          dispatch({
            type: SET_CURRENT_PATIENT_ANSWERS,
            answers: patientAnswers,
          })
          setAnswersResponseState(ResponseState.Success)
          return
        }
        setAnswersResponseState(ResponseState.Failure)
        const { errorCode } = response
        if (errorCode === ResponseErrorCode.NotAuthenticated) {
          dispatch({ type: LOG_OUT })
        }
      } catch {
        setAnswersResponseState(ResponseState.Failure)
      }
    }

    handleGetPatientResults()
    handleGetPatientAnswers()
  }, [currentPatientId, dispatch, resultsResponseState, answersResponseState])

  useEffect(() => {
    if (
      resultsResponseState === ResponseState.Failure ||
      answersResponseState === ResponseState.Failure
    ) {
      setDataResponseState(ResponseState.Failure)
    }

    if (
      resultsResponseState === ResponseState.Pending ||
      answersResponseState === ResponseState.Pending
    ) {
      setDataResponseState(ResponseState.Pending)
    }

    if (
      resultsResponseState === ResponseState.Success &&
      answersResponseState === ResponseState.Success
    ) {
      setDataResponseState(ResponseState.Success)
    }
  }, [resultsResponseState, answersResponseState])

  useEffect(() => {
    const handleGetPatientAccesses = async () => {
      setAccessesResponseState(ResponseState.Pending)
      dispatch({
        type: SET_CURRENT_PATIENT_ACCESSES,
        currentPatientAccesses: [],
      })

      try {
        const response = await getPatientAccesses(currentPatientId)

        const { success, accesses } = response

        if (success) {
          dispatch({
            type: SET_CURRENT_PATIENT_ACCESSES,
            accesses: accesses,
          })
          setAccessesResponseState(ResponseState.Success)
          return
        }
        setAccessesResponseState(ResponseState.Failure)
        const { errorCode } = response
        if (errorCode === ResponseErrorCode.NotAuthenticated) {
          dispatch({ type: LOG_OUT })
        }
      } catch {
        setAccessesResponseState(ResponseState.Failure)
      }
    }

    if (ResponseState.Success === dataResponseState) {
      handleGetPatientAccesses()
    }
  }, [currentPatientId, dataResponseState, dispatch])

  return {
    dataResponseState,
    accessesResponseState,
  }
}

export default useGetPatientData
