import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useGetPatientData from '../../hooks/useGetPatientData'

import { AccessSummary } from '../../Components/AccessSummary'
import { AnswersTable } from '../../Components/AnswersTable'
import { ButtonAsText } from '../../Components/ButtonAsText'
import { DiagnosisCard } from '../../Components/DiagnosisCard'
import { GeneralHealthOverview } from '../../Components/GeneralHealthOverview'
import { AccessLogModal } from '../../Components/Modal/AccessLogModal'
import { PageHeading } from '../../Components/PageHeading'
import { Section } from '../../Components/Section'
import SeverityInfoModal from '../../Components/SeverityInfoModal'
import { TimelineGraph } from '../../Components/TimelineGraph'

import { ResponseState } from '../../Constants/enums'
import { UserContext } from '../../context'
import { ResultsText } from '../../texts'
import {
  getAnswerValueFromQuestionId,
  getComparisonDirection,
  getSeverityLevelsFromResults,
  transformAnswersResultsToTableData,
  transformResultsAnswersToDataPoints,
} from '../../utils'
import Styles from './results.module.scss'

export const Results = () => {
  const { dataResponseState } = useGetPatientData()

  const {
    state: { currentPatient },
  } = useContext(UserContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (!currentPatient?.id) {
      navigate('/')
      return
    }
  }, [currentPatient, navigate])

  return (
    <>
      <ResultPageHeading currentPatient={currentPatient} />
      {dataResponseState === ResponseState.Pending && <IsLoading />}
      {dataResponseState === ResponseState.Success && <ResultContent />}
      {dataResponseState === ResponseState.Failure && <LoadingFailed />}
    </>
  )
}

const ResultContent = () => {
  const {
    state: {
      currentPatient: { results: patientResults, answers: patientAnswers },
    },
  } = useContext(UserContext)

  const [showSeverityModal, setShowSeverityModal] = useState(false)

  const handleSeverityModalOpen = () => {
    setShowSeverityModal(true)
  }

  const handleSeverityModalClose = () => {
    setShowSeverityModal(false)
  }

  if (patientResults.length === 0) {
    return (
      <div className={Styles.warning}>{ResultsText.noResultsForPatient}</div>
    )
  }
  const latestResults = patientResults[0]
  const previousResults = patientResults.length > 1 ? patientResults[1] : null

  const date = latestResults.answered_date.substring(0, 10)

  const timeLineData = transformResultsAnswersToDataPoints(
    patientResults,
    patientAnswers,
  )
  const answersTableData = transformAnswersResultsToTableData(
    patientAnswers,
    patientResults,
  )

  return (
    <>
      <Section heading={ResultsText.latestResult} subHeading={<p>{date}</p>}>
        <DiagnosisCards
          latestResults={latestResults}
          previousResults={previousResults}
        />
        <hr />
        <GeneralHealth
          latestResults={latestResults}
          date={date}
          patientAnswers={patientAnswers}
        />
      </Section>
      <Section
        heading={ResultsText.resultOverTimeHeading}
        subHeading={
          <TimelineSubHeading handleClick={handleSeverityModalOpen} />
        }
      >
        <TimelineGraph timeLineData={timeLineData} />
      </Section>
      {answersTableData && (
        <Section heading={ResultsText.answersHeading}>
          <AnswersTable answersTableData={answersTableData} />
        </Section>
      )}
      <SeverityInfoModal
        show={showSeverityModal}
        handleClose={handleSeverityModalClose}
      />
    </>
  )
}

const ResultPageHeading = (props) => {
  const { currentPatient } = props

  const [showAccessLogModal, setShowAccessLogModal] = useState(false)

  const handleAccessLogModalClose = () => {
    setShowAccessLogModal(false)
  }

  if (!currentPatient) return null
  const heading = ResultsText.heading
  const subHeading = `${ResultsText.subHeading} ${currentPatient.firstName} ${currentPatient.lastName}, ${currentPatient.nationalID}`
  return (
    <div className={Styles.title}>
      <PageHeading heading={heading} subHeading={subHeading} />
      <AccessSummary
        handleClick={() => {
          setShowAccessLogModal(true)
        }}
      />
      <AccessLogModal
        show={showAccessLogModal}
        handleClose={handleAccessLogModalClose}
      />
    </div>
  )
}

const LoadingFailed = () => {
  return <div className={Styles.warning}>{ResultsText.loadingFailed}</div>
}

const IsLoading = () => {
  return <div className={Styles.warning}>{ResultsText.loading}</div>
}

const TimelineSubHeading = (props) => {
  const { handleClick } = props

  return (
    <>
      <p>
        {`${ResultsText.resultOverTimeParagraph1} `}
        <br />
        {`${ResultsText.resultOverTimeParagraph2} `}
        <br />
        <ButtonAsText onClick={handleClick} label={ResultsText.readMore} />
      </p>
    </>
  )
}

const GeneralHealth = (props) => {
  const { latestResults, date, patientAnswers } = props
  const surveyAnswerId = latestResults['survey_answer_id']
  const surveyAnswer = patientAnswers.find(
    (ans) => ans['id'] === surveyAnswerId,
  )

  const genHealthAnswer = getAnswerValueFromQuestionId(
    surveyAnswer,
    'PulsenGenText',
  )

  return <GeneralHealthOverview answer={genHealthAnswer} date={date} />
}

const DiagnosisCards = (props) => {
  const { latestResults, previousResults } = props

  const severityLevels = getSeverityLevelsFromResults(latestResults)
  const resultComparison = {}

  if (previousResults) {
    const latestPredictions = latestResults.predictions
    const previousPredictions = previousResults.predictions

    resultComparison.depression = getComparisonDirection(
      latestPredictions.depression,
      previousPredictions.depression,
    )
    resultComparison.stress = getComparisonDirection(
      latestPredictions.stress,
      previousPredictions.stress,
    )
    resultComparison.anxiety = getComparisonDirection(
      latestPredictions.anxiety,
      previousPredictions.anxiety,
    )
  }

  return (
    <div className={Styles.cardHolder}>
      <DiagnosisCard
        label={ResultsText.MDD}
        diagnosisSeverityLevel={severityLevels.MDD}
        comparedToPrevious={resultComparison.depression}
      />
      <DiagnosisCard
        label={ResultsText.SED}
        diagnosisSeverityLevel={severityLevels.SED}
        comparedToPrevious={resultComparison.stress}
      />
      <DiagnosisCard
        label={ResultsText.GAD}
        diagnosisSeverityLevel={severityLevels.GAD}
        comparedToPrevious={resultComparison.anxiety}
      />
    </div>
  )
}
