import Styles from './pageHeading.module.scss'

export const PageHeading = (props) => {
  const { heading, subHeading } = props

  return (
    <hgroup className={Styles.pageHeading}>
      <h1 className={Styles.heading}>{heading}</h1>
      {subHeading && <p className={Styles.subHeading}>{subHeading}</p>}
    </hgroup>
  )
}
