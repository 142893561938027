import React from 'react'
import ReactMarkdown from 'react-markdown'

export const TextElement = (props) => {
  const { children } = props
  return <ReactMarkdown linkTarget="_blank">{children}</ReactMarkdown>
}

export const TextWrapper = (props) => {
  const { texts = [] } = props
  return (
    <div>
      {texts.map((text, i) => (
        <TextElement key={i}>{text}</TextElement>
      ))}
    </div>
  )
}
