import React from 'react'

import './pageWrapper.scss'

export const PageWrapper = (props) => {
  const { children } = props
  return (
    <div className="page-wrapper">
      <div className="layout-wrapper">{children}</div>
    </div>
  )
}
