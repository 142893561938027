import { useContext, useEffect } from 'react'

import useGetPatients from '../../hooks/useGetPatients'

import { Onboarding } from '../../Components/Onboarding'
import { PageHeading } from '../../Components/PageHeading'
import { PatientsTable } from '../../Components/PatientsTable'
import SearchForm from '../../Components/SearchForm'
import { Section } from '../../Components/Section'

import {
  ONBOARDING_TYPE,
  ResponseErrorCode,
  ResponseState,
} from '../../Constants/enums'
import { RESET_CURRENT_PATIENT_DATA } from '../../Constants/user'
import { UserContext } from '../../context'
import { General, MainText } from '../../texts'

export const Main = () => {
  const { responseStateGetPatientsList, responseErrorCodeGetPatientsList } =
    useGetPatients()

  const {
    state: { patients, clinicName, searchResults },
    dispatch,
  } = useContext(UserContext)

  useEffect(() => {
    dispatch({ type: RESET_CURRENT_PATIENT_DATA })
  }, [dispatch])

  switch (responseStateGetPatientsList) {
    case ResponseState.Failure:
      if (responseErrorCodeGetPatientsList === ResponseErrorCode.NotActivated) {
        return <Onboarding onboardingType={ONBOARDING_TYPE.ACTIVATING} />
      }

      return (
        <>
          <PageHeading heading={MainText.patientList} />
          <p>{MainText.getPatientsListError}</p>
        </>
      )

    case ResponseState.Success:
      if (patients.length) {
        return (
          <>
            <PageHeading
              heading={`${MainText.patientList}, ${clinicName}`}
              subHeading={MainText.patientListSubHeading}
            />
            <SearchForm />
            <Section padding={false}>
              <PatientsTable
                patientsList={searchResults.length ? searchResults : patients}
              />
            </Section>
          </>
        )
      }

      return <Onboarding onboardingType={ONBOARDING_TYPE.WAITING} />

    default:
      return (
        <>
          <PageHeading heading={MainText.patientList} />
          <p>{General.loading}</p>
        </>
      )
  }
}
