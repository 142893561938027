import { Color } from '../../../Constants/enums'
import SeverityAccents from '../../../styles/_severityColors.module.scss'
import { ResultsText } from '../../../texts'
import {
  getColorFromSeverityLevel,
  getDepressionAnxietyStressSeverityNumber,
} from '../../../utils'
import './customTooltip.scss'

export const CustomTooltip = (props) => {
  const { active, payload, activePoint, contentOffset } = props

  const trianglePointerOffset = 10
  const dotHeightOffset = 17

  const colorAccentClass = {
    [Color.Green]: [SeverityAccents.greenFg],
    [Color.Yellow]: [SeverityAccents.yellowFg],
    [Color.Red]: [SeverityAccents.redFg],
  }

  if (active && payload && payload.length && activePoint) {
    const activePayload = payload.find(
      (element) => element.name === activePoint,
    )

    if (activePayload) {
      const severityLevel = getDepressionAnxietyStressSeverityNumber(
        activePayload.value,
      )
      const severityLevelColor = getColorFromSeverityLevel(severityLevel)
      const severityText = ResultsText.diagnosisSeverityLabels[severityLevel]
      const { date, typeofsurvey } = activePayload.payload

      return (
        <div
          className="tooltip"
          style={{
            position: 'relative',
            top: contentOffset.top - trianglePointerOffset - dotHeightOffset,
            left: contentOffset.left - trianglePointerOffset,
            visibility: `${contentOffset.left !== 0 ? 'visible' : 'hidden'}`,
          }}
        >
          {typeofsurvey && (
            <p>
              <u>{typeofsurvey}</u>
            </p>
          )}
          <p>
            {`${ResultsText.level} ${severityLevel} · `}{' '}
            <span className={colorAccentClass[severityLevelColor]}>
              {' '}
              {severityText}
            </span>
          </p>
          <p>{ResultsText.diagnosisSeverityLabelsDescription[severityLevel]}</p>
          <p className="date">{date}</p>
        </div>
      )
    }
  }
}
