import React from 'react'

export const Icons = {
  arrowBack: (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1089 12.0021L4.7615 12.0021M4.7615 12.0021L11.4352 18.6758M4.7615 12.0021L11.4352 5.32837"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  cross: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33721 7.84575L0.691406 2.19992L2.1999 0.691406L7.84572 6.33723L13.4914 0.691406L15.0001 2.19992L9.35424 7.84575L15.0001 13.4915L13.4914 15L7.84572 9.35424L2.1999 15L0.691406 13.4915L6.33721 7.84575Z"
        fill="currentColor"
      />
    </svg>
  ),
  GAD: (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="18" height="18" rx="9" fill="currentColor" />
    </svg>
  ),
  MDD: (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.13398 0.657714C8.51888 -0.00895226 9.48112 -0.00895178 9.86602 0.657715L17.6603 14.1577C18.0452 14.8244 17.564 15.6577 16.7942 15.6577H1.20577C0.435971 15.6577 -0.0451542 14.8244 0.339746 14.1577L8.13398 0.657714Z"
        fill="currentColor"
      />
    </svg>
  ),
  SED: (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="16" height="16" rx="3" fill="currentColor" />
    </svg>
  ),
}
