import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import useIdle from '../../hooks/useIdle'

import { NotificationType } from '../../Constants/enums'
import { UserContext } from '../../context'
import { MenuBar } from '../MenuBar'
import { IdleWarning } from '../Modal/IdleWarning'
import { TooltipWrapper } from '../TooltipWrapper'
import './protectedLayout.scss'

export const ProtectedLayout = () => {
  const {
    state: { isLoggedIn, notification },
  } = useContext(UserContext)
  const { resetIdleTimer } = useIdle()

  if (!isLoggedIn) {
    return <Navigate to="/login" />
  }

  const showIdleNotification =
    notification === NotificationType.IdleTimeoutWarning

  return (
    <main>
      <nav>
        <MenuBar />
      </nav>
      <article>
        <div className="outer">
          <div className="inner">
            <Outlet />
          </div>
        </div>
      </article>
      <IdleWarning
        show={showIdleNotification}
        resetIdleTimer={resetIdleTimer}
      />
      <TooltipWrapper />
    </main>
  )
}
