import classNames from 'classnames'

import { ButtonText } from '../../texts'
import { Button, ButtonIcon, ButtonStyle } from '../Button'
import { TextWrapper } from '../TextWrapper'
import Styles from './modal.module.scss'

export const Modal = (props) => {
  const { show, title, handleClose, texts, buttons = [], children } = props
  const modalClasses = classNames(Styles.modal, { [Styles.active]: show })
  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      handleClose()
    }
  }

  return (
    <div className={modalClasses} onClick={handleClickOutside} role="dialog">
      <div className={Styles.modalContent}>
        <div className={Styles.modalHeader}>
          <h2 className={Styles.heading}>{title}</h2>
          <Button
            onClick={handleClose}
            options={{ style: ButtonStyle.IconSmall, icon: ButtonIcon.Cross }}
            ariaLabel={ButtonText.close}
          />
        </div>
        <TextWrapper texts={texts} />
        {children}
        <div className={Styles.buttonContainer}>
          {buttons.map(({ style, label, action = () => {} }, i) => (
            <Button onClick={action} options={{ style }} key={i}>
              {label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}
