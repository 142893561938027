import Plausible from 'plausible-tracker'

import { TYPEOFSURVEYANSWERS } from './Constants/answers'
import {
  Color,
  ErrorPath,
  Progression,
  ResponseErrorCode,
} from './Constants/enums'
import { typeofsurveyAnswerLabels } from './texts'

const ENABLE_PLAUSIBLE = process.env.NODE_ENV !== 'development'

export const getCookieValue = (name) => {
  const cookie = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith(`${name}=`))

  const cookieValue = cookie ? decodeURIComponent(cookie.split('=')[1]) : ''
  return cookieValue
}

export const initAnalytics = () => {
  if (!ENABLE_PLAUSIBLE) {
    return
  }
  const plausible = Plausible({
    domain: process.env.REACT_APP_FRONTEND_HOSTNAME,
  })

  plausible.enableAutoPageviews()
  return plausible
}

export const trackEvent = (event) => {
  if (!ENABLE_PLAUSIBLE) {
    return
  }
  const { trackEvent } = Plausible()
  trackEvent(event)
}

export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return new Date(dateString).toLocaleDateString('sv-SE', options)
}

const SEVERITY_NUMBER_THRESHOLDS = {
  1: 1.5,
  2: 2.5,
  3: 3.5,
  4: 4.5,
  5: 5.5,
  6: 6.5,
  7: 8,
}

const getLevelComparison = (currentValue, previousValue) => {
  if (previousValue === currentValue) {
    return Progression.NoChange
  }
  if (currentValue > previousValue) {
    return Progression.Increased
  }
  return Progression.Decreased
}

export const getDepressionAnxietyStressSeverityNumber = (initialValue) => {
  const severityValue = getAllowedGraphValue(initialValue)
  const severityNumber = Object.keys(SEVERITY_NUMBER_THRESHOLDS).find(
    (severityNumber) =>
      severityValue < SEVERITY_NUMBER_THRESHOLDS[severityNumber],
  )
  return severityNumber
}

export const getComparisonDirection = (
  currentPrediction,
  previousPrediction,
) => {
  const currentValue = getDepressionAnxietyStressSeverityNumber(
    currentPrediction.diagnosis_severity.value,
  )
  const previousValue = getDepressionAnxietyStressSeverityNumber(
    previousPrediction.diagnosis_severity.value,
  )

  return getLevelComparison(currentValue, previousValue)
}

export const getAnswerValueFromQuestionId = (surveyAnswer, questionId) => {
  const answerGroups = surveyAnswer.answers.groups

  for (const group of answerGroups) {
    const answer = group.answers.find((answer) => answer.id === questionId)
    if (answer !== undefined) {
      return answer.value
    }
  }
}

export const getColorFromSeverityLevel = (severityLevel) => {
  const severityToColorMapping = {
    1: Color.Green,
    2: Color.Yellow,
    3: Color.Yellow,
    4: Color.Red,
    5: Color.Red,
    6: Color.Red,
    7: Color.Red,
  }

  return severityToColorMapping[severityLevel]
}

export const getColorFromSeverityScore = (severityScore) => {
  const severityLevel = getDepressionAnxietyStressSeverityNumber(severityScore)
  return getColorFromSeverityLevel(severityLevel)
}

export const getSeverityLevelsFromResults = (results) => {
  return {
    GAD: getDepressionAnxietyStressSeverityNumber(
      results.predictions.anxiety.diagnosis_severity.value,
    ),
    MDD: getDepressionAnxietyStressSeverityNumber(
      results.predictions.depression.diagnosis_severity.value,
    ),
    SED: getDepressionAnxietyStressSeverityNumber(
      results.predictions.stress.diagnosis_severity.value,
    ),
  }
}

export const getMaxSeverityLevel = (results) => {
  const anxietySeverityLevel = getDepressionAnxietyStressSeverityNumber(
    results.predictions.anxiety.diagnosis_severity.value,
  )
  const depressionSeverityLevel = getDepressionAnxietyStressSeverityNumber(
    results.predictions.depression.diagnosis_severity.value,
  )
  const stressSeverityLevel = getDepressionAnxietyStressSeverityNumber(
    results.predictions.stress.diagnosis_severity.value,
  )
  const maxSeverityLevel = Math.max(
    anxietySeverityLevel,
    depressionSeverityLevel,
    stressSeverityLevel,
  )
  return maxSeverityLevel
}

const typeofsurveyLabelFromAnswer = (answer) => {
  switch (answer) {
    case TYPEOFSURVEYANSWERS.pre1:
    case TYPEOFSURVEYANSWERS.pre2:
      return typeofsurveyAnswerLabels.pre

    case TYPEOFSURVEYANSWERS.during1:
    case TYPEOFSURVEYANSWERS.during2:
      return typeofsurveyAnswerLabels.during

    case TYPEOFSURVEYANSWERS.followUp1:
      return typeofsurveyAnswerLabels.followUp

    case TYPEOFSURVEYANSWERS.post1:
      return typeofsurveyAnswerLabels.post

    default:
      return undefined
  }
}

export const MAX_GRAPH_VALUE = 7.5
export const MIN_GRAPH_VALUE = 0.5
export const getAllowedGraphValue = (initialValue) => {
  let allowedValue = Math.min(initialValue, MAX_GRAPH_VALUE)
  allowedValue = Math.max(allowedValue, MIN_GRAPH_VALUE)

  return allowedValue
}

export const transformResultsAnswersToDataPoints = (results, answers) => {
  return results.map((result) => {
    const { survey_answer_id } = result
    const correspondingAnswer = answers.find(
      (answer) => answer['id'] === survey_answer_id,
    )
    let typeofsurvey
    if (correspondingAnswer) {
      const typeofsurveyAnswer = getAnswerValueFromQuestionId(
        correspondingAnswer,
        'typeofsurvey',
      )
      typeofsurvey = typeofsurveyAnswer
        ? typeofsurveyLabelFromAnswer(typeofsurveyAnswer)
        : undefined
    }

    const MDDInitialValue =
      result.predictions.depression.diagnosis_severity.value
    const MDDGraphValue = getAllowedGraphValue(MDDInitialValue)
    const SEDInitialValue = result.predictions.stress.diagnosis_severity.value
    const SEDGraphValue = getAllowedGraphValue(SEDInitialValue)
    const GADInitialValue = result.predictions.anxiety.diagnosis_severity.value
    const GADGraphValue = getAllowedGraphValue(GADInitialValue)

    return {
      typeofsurvey,
      date: formatDate(result.answered_date),
      MDD: MDDGraphValue,
      SED: SEDGraphValue,
      GAD: GADGraphValue,
    }
  })
}

export const transformAnswersResultsToTableData = (answers, results) => {
  return answers.map((answer) => {
    const { id, submitted } = answer
    const date = formatDate(submitted)
    const genHealthAnswer = getAnswerValueFromQuestionId(
      answer,
      'PulsenGenText',
    )

    const correspondingResult = results.find(
      (result) => result['survey_answer_id'] === id,
    )

    let severityLevels
    if (
      correspondingResult &&
      correspondingResult['prediction_type'] === 'clinical_swe'
    ) {
      severityLevels = getSeverityLevelsFromResults(correspondingResult)
    }

    return {
      id,
      genHealthAnswer,
      date,
      severityLevels,
    }
  })
}

export const getErrorRouteFromErrorCode = (errorCode) => {
  switch (errorCode) {
    case ResponseErrorCode.MissingPendingClinician:
      return `/oops/${ErrorPath.AccessDenied}`
    case ResponseErrorCode.NotAuthenticated:
      return '/'
    default:
      return `/oops`
  }
}
