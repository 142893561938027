import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AssessmentType } from '../../Constants/enums'
import { SET_CURRENT_PATIENT } from '../../Constants/user'
import { UserContext } from '../../context'
import { PatientsListText, searchText } from '../../texts'
import Styles from './patientsTable.module.scss'

export const PatientsTable = (props) => {
  const { patientsList } = props

  return (
    <div className={Styles.tableWrapper}>
      <table className={Styles.patientsTable}>
        <thead>
          <tr>
            <th>{PatientsListText.name}</th>
            <th>{PatientsListText.nationalID}</th>
            <th>{PatientsListText.latestResult}</th>
            <AssessmentTypeHeader />
            <th className={Styles.accessibilityTableCell}></th>
          </tr>
        </thead>
        <tbody>
          <PatientsTableContent patientsList={patientsList} />
        </tbody>
      </table>
    </div>
  )
}

const AssessmentTypeHeader = () => {
  return Object.values(AssessmentType).map((assessmentType) => {
    return (
      <th key={assessmentType} className={Styles.assessmentType}>
        {PatientsListText[assessmentType]}
      </th>
    )
  })
}

const AssessmentTypeContent = (props) => {
  const { assessmentTypeCount } = props

  return Object.values(AssessmentType).map((assessmentType) => {
    const count = assessmentTypeCount[assessmentType]

    const assessmentTypeCountClasses = classNames(Styles.assessmentTypeCount, {
      [Styles.assessmentTypeCount__filled]: count,
    })

    return (
      <td key={assessmentType} className={Styles.assessmentType}>
        <span className={assessmentTypeCountClasses}>{count}</span>
      </td>
    )
  })
}

const PatientsTableContent = (props) => {
  const { patientsList } = props

  const { dispatch } = useContext(UserContext)
  const navigate = useNavigate()

  const [showPatient, setShowPatient] = useState(false)

  useEffect(() => {
    if (!showPatient) return
    navigate('results/')
  }, [showPatient, navigate])

  const handleClickPatientRow = (buttonId) => {
    document.getElementById(buttonId).click()
  }

  const handleClickAccessibleButton = (event, currentPatient) => {
    event.stopPropagation()
    dispatch({ type: SET_CURRENT_PATIENT, currentPatient })
    setShowPatient(true)
  }

  return patientsList.map((patientEntry) => {
    const { id, firstName, lastName, nationalID, surveySummary } = patientEntry

    const { latestSurveyDate, assessmentTypeCount } = surveySummary

    const accessibleButtonId = `button-${id}`

    return (
      <tr
        key={nationalID}
        onClick={() => handleClickPatientRow(accessibleButtonId)}
      >
        <td>{`${firstName} ${lastName}`}</td>
        <td>{nationalID}</td>
        <td className={Styles.assessmentDate}>{latestSurveyDate}</td>
        <AssessmentTypeContent assessmentTypeCount={assessmentTypeCount} />
        <td className={Styles.accessibilityTableCell}>
          <button
            id={accessibleButtonId}
            onClick={(event) =>
              handleClickAccessibleButton(event, patientEntry)
            }
          >
            {searchText.show}
          </button>
        </td>
      </tr>
    )
  })
}
