import classNames from 'classnames'

import Styles from './section.module.scss'

export const Section = (props) => {
  const { heading, subHeading, children, padding = true } = props

  const sectionClasses = classNames(Styles.section, {
    [Styles.padding]: padding,
  })

  const SectionHeading = () => {
    return (
      <>
        <hgroup>
          <h2 className={Styles.heading}>{heading}</h2>
          {subHeading && <div className={Styles.subHeading}>{subHeading}</div>}
        </hgroup>
        <hr />
      </>
    )
  }

  return (
    <section className={sectionClasses}>
      {heading && <SectionHeading />}
      {children}
    </section>
  )
}
