import { createContext, useEffect, useReducer } from 'react'

import userReducer, { userInitalState } from './reducers/user'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const { Provider } = UserContext

  const STORAGE_KEY = 'USER_STORAGE'
  let storedData = {}
  try {
    storedData = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
  } catch {
    storedData = {}
  }

  const userLocalStoreState = {
    ...userInitalState,
    ...storedData,
  }

  const [state, dispatch] = useReducer(userReducer, userLocalStoreState)

  useEffect(() => {
    const { isLoggedIn, notification } = state
    const data = { isLoggedIn, notification }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
  }, [state])

  const value = { state, dispatch }
  return <Provider value={value}>{children}</Provider>
}
