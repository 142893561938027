import classNames from 'classnames'
import React from 'react'

import './contentColumnsWrapper.scss'

const ContentColumnsWrapper = (props) => {
  const { children, mobileReverseOrder } = props

  const contentColumnsClassNames = classNames('content-columns', {
    'reverse-order': mobileReverseOrder,
  })

  return (
    <div className={contentColumnsClassNames}>
      {React.Children.map(children, (child, i) => {
        if (child) {
          return (
            <div key={i} className="content-column">
              {child}
            </div>
          )
        }
      })}
    </div>
  )
}

export default ContentColumnsWrapper
