import { ResultsText } from '../../texts'
import { SeverityOverview } from '../SeverityOverview'
import Styles from './answersTable.module.scss'

export const AnswersTable = (props) => {
  return (
    <div className={Styles.tableWrapper}>
      <table className={Styles.answersTable}>
        <thead>
          <tr>
            <th>{ResultsText.allAnswersToQuestionHowAreYou}</th>
            <th>{ResultsText.date}</th>
            <th>{ResultsText.severity}</th>
          </tr>
        </thead>
        <tbody>
          <AnswersTableContent answersTableData={props.answersTableData} />
        </tbody>
      </table>
    </div>
  )
}

const AnswersTableContent = (props) => {
  const { answersTableData } = props

  return answersTableData.map((answerData) => {
    const { id, genHealthAnswer, date, severityLevels } = answerData
    return (
      <tr key={id}>
        <td>
          <span className={Styles.answer}>{genHealthAnswer}</span>
        </td>
        <td>{date}</td>
        <td>
          <SeverityOverview severityLevels={severityLevels} rowId={id} />
        </td>
      </tr>
    )
  })
}
