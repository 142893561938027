import { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AuthenticationVerify } from './Screens/AuthenticationVerify'
import { Error } from './Screens/Error'
import { Main } from './Screens/Main'
import { Results } from './Screens/Results'
import { Welcome } from './Screens/Welcome'

import { ProtectedLayout } from './Components/ProtectedLayout'

import { ErrorPath } from './Constants/enums'
import { UserContext } from './context'

const App = () => {
  const {
    state: { isLoggedIn },
  } = useContext(UserContext)

  return (
    <Routes>
      <Route path="/" element={<ProtectedLayout />}>
        <Route path="/" element={<Main />} />
        <Route path="results/" element={<Results />} />
      </Route>

      <Route
        path="login/"
        element={isLoggedIn ? <Navigate to="/" /> : <Welcome />}
      />
      <Route path="authenticate/" element={<AuthenticationVerify />} />
      <Route path="oops/" element={<Error />} />
      <Route path="oops/:errorPath" element={<Error />} />
      <Route
        path="*"
        element={<Navigate to={`/oops/${ErrorPath.PageNotFound}`} />}
      />
    </Routes>
  )
}
export default App
