import classNames from 'classnames'

import { ResultsText } from '../../texts'
import Styles from './generalHealthOverview.module.scss'

export const GeneralHealthOverview = (props) => {
  const { answer, date } = props

  const answerContainerClasses = classNames(Styles.answerContainer)

  return (
    <>
      <h3 className={Styles.generalHealthOverview__heading}>
        {ResultsText.generalHealth} {date}
      </h3>
      <div className={Styles.generalHealthOverview}>
        <div className={answerContainerClasses}>
          <h4>{ResultsText.answerToQuestionHowAreYou}</h4>
          <div>{answer}</div>
        </div>
      </div>
    </>
  )
}
