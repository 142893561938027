import { AssessmentType } from '../Constants/enums'
import { formatDate } from '../utils'

const transformSurveySummary = (answers) => {
  const latestSurveyDate = answers.length
    ? formatDate(answers[0].submitted)
    : ''

  const assessmentTypeCount = {
    [AssessmentType.Pre]: 0,
    [AssessmentType.During]: 0,
    [AssessmentType.Post]: 0,
    [AssessmentType.FollowUp]: 0,
  }

  answers.forEach((answer) => {
    const { assessment_type: assessmentType } = answer
    if (!assessmentType) return
    assessmentTypeCount[assessmentType] += 1
  })

  return { latestSurveyDate, assessmentTypeCount }
}

const transformPatientsList = (patients) => {
  const {
    id,
    first_name: firstName,
    last_name: lastName,
    national_id: nationalID,
    answers,
  } = patients

  const surveySummary = transformSurveySummary(answers)

  return { id, firstName, lastName, nationalID, surveySummary }
}

export const transformGetPatientsResponse = (response) => {
  const {
    success,
    patients: rawPatientsList,
    error_code: errorCode,
    clinic_name: clinicName,
  } = response

  const patients =
    rawPatientsList &&
    rawPatientsList.map((patient) => transformPatientsList(patient))

  return { success, patients, errorCode, clinicName }
}
