import { useContext } from 'react'

import { logoutClinician } from '../Api'
import { LOG_OUT } from '../Constants/user'
import { UserContext } from '../context'

export const useLogout = () => {
  const { dispatch } = useContext(UserContext)

  const handleLogout = async () => {
    try {
      const { success } = await logoutClinician()
      if (!success) {
        // eslint-disable-next-line no-console
        console.log('Could not complete logout request, request failed.')
      }
    } catch {
      // eslint-disable-next-line no-console
      console.log('Could not complete logout request, no connection to server.')
    }
    dispatch({ type: LOG_OUT })
  }

  return { handleLogout }
}
