import { useNavigate, useParams } from 'react-router-dom'

import { Button, ButtonIcon, ButtonStyle } from '../../Components/Button'
import ContentColumnsWrapper from '../../Components/ContentColumnsWrapper'
import { ImageWrapper } from '../../Components/ImageWrapper'
import { PageHeading } from '../../Components/PageHeading'
import { PageWrapper } from '../../Components/PageWrapper'

import { ErrorPath } from '../../Constants/enums'
import error from '../../img/error.png'
import { ButtonText, errorText } from '../../texts'

const ErrorTextColumn = (props) => {
  const { texts } = props
  const navigate = useNavigate()

  const onBackButtonClick = () => {
    navigate('/')
  }

  return (
    <div>
      <p>{texts.preHeading}</p>
      <PageHeading heading={texts.heading} subHeading={texts.paragraph} />

      <Button
        onClick={onBackButtonClick}
        options={{ style: ButtonStyle.Secondary, icon: ButtonIcon.ArrowBack }}
      >
        {ButtonText.back}
      </Button>
    </div>
  )
}

const ErrorImageColumn = () => {
  return (
    <ImageWrapper>
      <img src={error} alt={''} />
    </ImageWrapper>
  )
}

export const Error = () => {
  const { errorPath } = useParams()

  const getErrorTexts = (errorPath) => {
    let texts = errorText[ErrorPath.DefaultError]

    if (errorPath) {
      const checkPathExist = Object.values(ErrorPath).includes(errorPath)
      const error = checkPathExist ? errorPath : ErrorPath.PageNotFound
      texts = errorText[error]
    }

    return texts
  }

  const texts = getErrorTexts(errorPath)

  return (
    <PageWrapper>
      <ContentColumnsWrapper mobileReverseOrder>
        <ErrorTextColumn texts={texts} />
        <ErrorImageColumn />
      </ContentColumnsWrapper>
    </PageWrapper>
  )
}
