import classNames from 'classnames'

import { ResultsText } from '../../../texts'
import { Icon } from '../../Icon'
import './customLegend.scss'

export const CustomLegend = (props) => {
  const { payload, onLegendButtonClick, activeLegendKeys } = props

  const handleClick = (key) => {
    onLegendButtonClick(key)
  }

  return (
    <div className="legendContainer">
      {payload.map((entry) => {
        const buttonClass = classNames('legendButton', {
          legendButton__disabled: !activeLegendKeys.includes(entry.value),
        })

        return (
          <button
            onClick={() => handleClick(entry.value)}
            className={buttonClass}
            key={`item-${entry.value}`}
          >
            <Icon iconType={entry.value} />
            {ResultsText[entry.value]}
          </button>
        )
      })}
    </div>
  )
}
