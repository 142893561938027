import React, { useContext } from 'react'

import { Modal } from '.'
import { NotificationType } from '../../Constants/enums'
import { CLEAR_NOTIFICATION } from '../../Constants/user'
import { UserContext } from '../../context'
import { ButtonText, notificationText } from '../../texts'
import { ButtonStyle } from '../Button'

export const IdleLogoutNotification = (props) => {
  const { show } = props

  const { dispatch } = useContext(UserContext)

  const handleClose = () => {
    dispatch({ type: CLEAR_NOTIFICATION })
  }

  const buttons = [
    {
      style: ButtonStyle.Primary,
      label: ButtonText.logIn,
      action: handleClose,
    },
  ]

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      title={notificationText[NotificationType.IdleTimeoutLogout].title}
      texts={notificationText[NotificationType.IdleTimeoutLogout].texts}
      buttons={buttons}
    />
  )
}
