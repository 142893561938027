export const ResponseState = {
  Pending: 'Pending',
  Success: 'Success',
  Failure: 'Failure',
  NotSent: 'NotSent',
}

export const ResponseErrorCode = {
  NotAuthenticated: 'not_authenticated',
  NotActivated: 'not_activated',
  HealthcheckApiError: 'healthcheck_api_error',
  MissingPendingClinician: 'missing_pending_clinician',
}

export const ErrorPath = {
  AccessDenied: 'access-denied',
  PageNotFound: 'page-not-found',
}

export const Progression = {
  Increased: 'increased',
  NoChange: 'noChange',
  Decreased: 'decreased',
}

export const Color = {
  Green: 'Green',
  Yellow: 'Yellow',
  Red: 'Red',
}

export const DiagnosisArea = {
  MDD: 'MDD',
  GAD: 'GAD',
  SED: 'SED',
}

export const NotificationType = {
  IdleTimeoutLogout: 'idle',
  IdleTimeoutWarning: 'idlewarning',
}

export const ONBOARDING_TYPE = {
  ACTIVATING: 'ACTIVATING',
  WAITING: 'WAITING',
}

export const AssessmentType = {
  Pre: 'pre',
  During: 'during',
  Post: 'post',
  FollowUp: 'followUp',
}
