import './menuButton.scss'

export const MenuButton = (props) => {
  const { children, onClick } = props

  const classes = 'menuButton'

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  )
}
