import React from 'react'

import Styles from './buttonAsText.module.scss'

export const ButtonAsTextStyle = {
  Default: 'default',
  Small: 'small',
}

export const ButtonAsText = ({
  onClick,
  label,
  style = ButtonAsTextStyle.Default,
}) => {
  return (
    <button onClick={onClick} className={Styles[style]}>
      {label}
    </button>
  )
}
