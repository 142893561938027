import { useContext } from 'react'

import { ButtonAsText, ButtonAsTextStyle } from '../../Components/ButtonAsText'
import { InsertJsxInString } from '../../Components/InsertJsxInString'
import { MenuButton } from '../../Components/MenuButton'

import { UserContext } from '../../context'
import { AccessSummaryText } from '../../texts'
import Styles from './accessSummary.module.scss'
import accessListIconSrc from './eye.png'
import { summarizePatientAccesses } from './utils'

const NumericSummary = (props) => {
  const { handleClick, numberOfAdditionalClinicians } = props

  return (
    <div className={Styles.numericSummary}>
      <InsertJsxInString
        string={AccessSummaryText.numericSummary}
        replace="{{link}}"
        element={
          <ButtonAsText
            onClick={handleClick}
            label={
              numberOfAdditionalClinicians + ' ' + AccessSummaryText.others
            }
            style={ButtonAsTextStyle.Small}
          />
        }
      />
    </div>
  )
}

export const AccessSummary = (props) => {
  const { handleClick } = props
  const {
    state: {
      currentPatient: { accesses: patientAccesses },
    },
  } = useContext(UserContext)

  if (!patientAccesses || patientAccesses.length === 0) {
    return
  }

  const { summarizedAccesses, numberOfAdditionalClinicians } =
    summarizePatientAccesses(patientAccesses)

  return (
    <div className={Styles.accessSummary}>
      <div className={Styles.accessSummaryBody}>
        <ul>
          {summarizedAccesses.map((accessData) => {
            const { accessDate, clinicianName, clinicianId } = accessData
            return (
              <li key={clinicianId}>
                <span className={Styles.name}>{clinicianName}</span>
                <wbr />
                <span className={Styles.date}>{accessDate}</span>
              </li>
            )
          })}
        </ul>
        <NumericSummary
          numberOfAdditionalClinicians={numberOfAdditionalClinicians}
          handleClick={handleClick}
        />
      </div>
      <MenuButton onClick={handleClick}>
        <img
          width="20"
          height="20"
          src={accessListIconSrc}
          alt={AccessSummaryText.buttonAltText}
        />
      </MenuButton>
    </div>
  )
}
