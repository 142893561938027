import { useLocation } from 'react-router-dom'

import useAuthenticateClinician from '../../hooks/useAuthenticateClinician'

import { PageWrapper } from '../../Components/PageWrapper'

import { VerifyText } from '../../texts'

export const AuthenticationVerify = () => {
  const location = useLocation()

  const getQueryParam = (key) => {
    const result = new URLSearchParams(location.search).get(key)
    return result ? result : ''
  }

  const grandIDSession = getQueryParam('grandidsession')
  const verificationMethod = getQueryParam('method')

  useAuthenticateClinician(grandIDSession, verificationMethod)

  return (
    <PageWrapper>
      <h1>{VerifyText.pleaseWait}</h1>
      <p>{VerifyText.authenticationInProgress}</p>
    </PageWrapper>
  )
}
