import { useEffect, useState } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { DiagnosisArea } from '../../Constants/enums'
import { ResultsText } from '../../texts'
import { CustomDot } from './CustomDot'
import { CustomLegend } from './CustomLegend'
import { CustomTick } from './CustomTick'
import { CustomTooltip } from './CustomTooltip'
import SeverityColorGrid from './SeverityColorGrid.svg'
import './timelineGraph.scss'

export const TimelineGraph = (props) => {
  const { timeLineData } = props

  const keys = [DiagnosisArea.MDD, DiagnosisArea.SED, DiagnosisArea.GAD]

  const [activeLegendKeys, setActiveLegendKeys] = useState(keys)
  const [hidden, setHidden] = useState({
    MDD: false,
    SED: false,
    GAD: false,
  })
  const [activePoint, setActivePoint] = useState('')
  const [tooltipSize, setTooltipSize] = useState({ width: 10, height: 10 })

  const onLegendButtonClick = (key) => {
    let currentActiveKeys = activeLegendKeys

    if (currentActiveKeys.includes(key)) {
      currentActiveKeys.splice(currentActiveKeys.indexOf(key), 1)
      setHidden({ ...hidden, [key]: true })
    } else {
      currentActiveKeys.push(key)
      setHidden({ ...hidden, [key]: false })
    }

    setActiveLegendKeys(currentActiveKeys)
  }

  useEffect(() => {
    const tooltip = document.querySelector('.recharts-tooltip-wrapper')
    if (!tooltip) return

    const { width, height } = tooltip.getBoundingClientRect()
    setTooltipSize({ width, height })
  }, [activePoint])

  const timelineMinWidth = timeLineData.length * 80

  return (
    <div className="timeline-wrapper">
      <ResponsiveContainer
        width="100%"
        minWidth={timelineMinWidth}
        height={480}
      >
        <LineChart
          key={`linechart-${activeLegendKeys.length}`}
          style={{
            backgroundImage: `url(${SeverityColorGrid})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 338px',
            backgroundPosition: '60px 56px',
          }}
          data={timeLineData}
          margin={{
            top: 20,
            bottom: 56,
          }}
        >
          <Tooltip
            content={
              <CustomTooltip
                activePoint={activePoint}
                contentOffset={{
                  left: -tooltipSize.width / 2,
                  top: -tooltipSize.height,
                }}
              />
            }
            allowEscapeViewBox={{ x: true, y: true }}
            cursor={false}
            isAnimationActive={false}
          />
          <Legend
            verticalAlign="top"
            wrapperStyle={{ top: 0, width: '100%' }}
            content={
              <CustomLegend
                onLegendButtonClick={onLegendButtonClick}
                activeLegendKeys={activeLegendKeys}
              />
            }
          />
          <CartesianGrid vertical={false} />
          {keys.map((key) => {
            return (
              <Line
                key={key}
                id={`line-${key}`}
                isAnimationActive={false}
                type="monotone"
                dataKey={key}
                stroke="#344054"
                strokeWidth="1.5"
                dot={<CustomDot />}
                label={key}
                hide={hidden[key]}
                activeDot={
                  <CustomDot
                    active={true}
                    activePoint={activePoint}
                    onMouseOver={(e) => setActivePoint(key)}
                    onMouseLeave={(e) => setActivePoint('')}
                  />
                }
              />
            )
          })}
          <XAxis
            dataKey="date"
            reversed={true}
            padding={{ left: 70, right: 50 }}
            tick={{ fill: '#475467', fontSize: '10px', angle: -45 }}
            tickLine={false}
            tickMargin={20}
            interval={0}
            label={{
              value: ResultsText.date,
              position: 'left',
              dx: 15,
              dy: 14,
              fill: '#475467',
              fontSize: '10px',
            }}
          />
          <YAxis
            domain={[0.5, 7.5]}
            tick={<CustomTick dy={30} />}
            ticks={[1.5, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5]}
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            label={{
              value: ResultsText.severity,
              angle: -90,
              position: 'insideLeft',
              fill: '#475467',
              fontSize: '10px',
            }}
          ></YAxis>
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
