import { useContext } from 'react'

import useInitiateGrandIDVerification from '../../hooks/useInitiateGrandIDVerification'

import { Button } from '../../Components/Button'
import { IdleLogoutNotification } from '../../Components/Modal/IdleLogoutNotification'
import { PageWrapper } from '../../Components/PageWrapper'
import { TextElement } from '../../Components/TextWrapper'

import { NotificationType, ResponseState } from '../../Constants/enums'
import { UserContext } from '../../context'
import logoWise from '../../img/wise.svg'
import { ButtonText, WelcomeText } from '../../texts'
import './welcome.scss'

const LoginOptions = () => {
  const { handleInitiateBankIDVerification, responseStateInitVerification } =
    useInitiateGrandIDVerification()

  const ErrorMessage = () => {
    return <p>{WelcomeText.requestError}</p>
  }

  return (
    <>
      <div className="button-wrapper">
        <Button onClick={handleInitiateBankIDVerification}>
          {ButtonText.logInWithBankID}
        </Button>
      </div>

      {responseStateInitVerification === ResponseState.Failure && (
        <ErrorMessage />
      )}
    </>
  )
}

const WiseLogo = () => {
  return (
    <img src={logoWise} alt="Wise" width="70" height="70" className="logo" />
  )
}

const TopText = () => {
  return (
    <div>
      <h1 className="welcome-heading">{WelcomeText.heading}</h1>
      <TextElement className="welcome-text">
        {WelcomeText.paragraph1}
      </TextElement>
    </div>
  )
}

const BottomText = () => {
  return <TextElement>{WelcomeText.paragraph2}</TextElement>
}

export const Welcome = () => {
  const {
    state: { notification },
  } = useContext(UserContext)

  const showIdleNotification =
    notification === NotificationType.IdleTimeoutLogout

  return (
    <PageWrapper>
      <div className="welcome">
        <WiseLogo />
        <TopText />
        <LoginOptions />
        <BottomText />
      </div>
      <IdleLogoutNotification show={showIdleNotification} />
    </PageWrapper>
  )
}
