import { useCallback, useEffect, useRef, useState } from 'react'

const TOOLTIP_WRAPPER_ID = 'tooltip-wrapper'
const TOOLTIP_OUTER_CLASS = 'tooltip-outer'
const TOOLTIP_INNER_CLASS = 'tooltip-inner'

const useTooltips = () => {
  const showTooltipRef = useRef(null)
  const [isActive, setIsActive] = useState(false)

  const activateTooltip = useCallback((tooltipData) => {
    const tooltipContainer = document.querySelector(`#${TOOLTIP_WRAPPER_ID}`)
    if (!tooltipContainer) return

    tooltipContainer.innerHTML = ''
    const tooltipOuterElement = document.createElement('div')
    tooltipOuterElement.className = TOOLTIP_OUTER_CLASS
    tooltipOuterElement.id = tooltipData.id
    tooltipOuterElement.style.left = tooltipData.left
    tooltipOuterElement.style.top = tooltipData.top
    tooltipOuterElement.style.opacity = '1'

    const tooltipInnerElement = document.createElement('span')
    tooltipInnerElement.className = TOOLTIP_INNER_CLASS
    tooltipInnerElement.textContent = tooltipData.text
    tooltipInnerElement.setAttribute('role', 'tooltip')

    tooltipContainer
      .appendChild(tooltipOuterElement)
      .appendChild(tooltipInnerElement)
  }, [])

  const deactivateTooltip = useCallback((tooltipData) => {
    const tooltipContainer = document.querySelector(`#${TOOLTIP_WRAPPER_ID}`)
    if (!tooltipContainer) return

    const tooltip = tooltipContainer.querySelector(`#${tooltipData.id}`)
    if (!tooltip) return

    tooltip.style.opacity = '0'
    setTimeout(() => {
      try {
        tooltip.parentElement.removeChild(tooltip)
      } catch {}
    }, 300)
  }, [])

  const handleMouseOver = useCallback(
    (event, tooltipData) => {
      if (isActive) return
      setIsActive(true)
      const { width, x } = showTooltipRef.current.getBoundingClientRect()
      tooltipData.left = `${x + width / 2}px`
      tooltipData.top = `${event.clientY - 16}px`
      activateTooltip(tooltipData)
    },
    [activateTooltip, isActive],
  )

  const handleMouseOut = useCallback(
    (tooltipData) => {
      if (!isActive) return
      setIsActive(false)
      deactivateTooltip(tooltipData)
    },
    [deactivateTooltip, isActive],
  )

  useEffect(() => {
    if (!showTooltipRef.current) {
      return
    }
    const node = showTooltipRef.current
    const id = `tooltip-${node.id}`
    const text = node.dataset.tooltip
    if (!text) {
      return
    }

    const tooltipData = {
      id,
      text,
    }

    node.addEventListener('mouseenter', (event) => {
      handleMouseOver(event, tooltipData)
    })
    node.addEventListener(
      'mouseleave',
      () => {
        handleMouseOut(tooltipData)
      },
      {
        passive: false,
        once: true,
      },
    )

    return () => {
      node.removeEventListener('mouseenter', handleMouseOver)
      node.removeEventListener('mouseleave', handleMouseOut)
    }
  }, [handleMouseOut, handleMouseOver])

  return { showTooltipRef }
}

export default useTooltips
