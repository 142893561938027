import { DiagnosisArea } from '../../../Constants/enums'

export const CustomDot = (props) => {
  const { cx, cy, dataKey, active, activePoint, onMouseOver, onMouseLeave } =
    props

  const highlight = active && activePoint === dataKey

  const elementOffset = {
    GAD: { normal: 5, highlighted: 8 },
    SED: { normal: 5, highlighted: 8 },
    MDD: { normal: 7, highlighted: 10 },
  }

  return (
    <svg
      x={
        highlight
          ? cx - elementOffset[dataKey].highlighted
          : cx - elementOffset[dataKey].normal
      }
      y={
        highlight
          ? cy - elementOffset[dataKey].highlighted
          : cy - elementOffset[dataKey].normal
      }
      width={highlight ? 20 : 14}
      height={highlight ? 17 : 11}
      fill="#344054"
      viewBox="0 0 20 16"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {dataKey === DiagnosisArea.SED && <rect width="16" height="16" />}
      {dataKey === DiagnosisArea.GAD && <circle cx="8" cy="8" r="8" />}
      {dataKey === DiagnosisArea.MDD && (
        <path d="M10 0L19.5263 16.5H0.473721L10 0Z" />
      )}
    </svg>
  )
}
