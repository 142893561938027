import { useContext } from 'react'

import { Modal } from '../../Components/Modal'

import { UserContext } from '../../context'
import { ButtonText } from '../../texts'
import { AccessLogModalTexts } from '../../texts'
import { ButtonStyle } from '../Button'
import Styles from './accessLogModal.module.scss'

export const AccessLogModal = (props) => {
  const { show, handleClose } = props
  const {
    state: { currentPatient },
  } = useContext(UserContext)

  if (!currentPatient?.accesses) {
    return
  }

  const {
    firstName: patientFirstName,
    lastName: patientLastName,
    accesses: patientAccesses,
  } = currentPatient

  const buttons = [
    {
      style: ButtonStyle.Primary,
      label: ButtonText.close,
      action: handleClose,
    },
  ]

  const title = AccessLogModalTexts.title
    .replace('{{firstName}}', patientFirstName)
    .replace('{{lastName}}', patientLastName)

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      title={title}
      buttons={buttons}
    >
      <div className={Styles.content}>
        <ul data-testid="access-log-modal-list">
          {patientAccesses.map((access, i) => {
            return (
              <li key={i}>
                <span className={Styles.name}>{access.clinicianName}</span>
                <br />
                <span className={Styles.date}>{access.accessDate}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </Modal>
  )
}
