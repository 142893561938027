import React from 'react'

import { ONBOARDING_TYPE } from '../../Constants/enums'
import { onboardingImageAlt } from '../../texts'
import activating_001 from './images/onboarding_activating_001.png'
import activating_002 from './images/onboarding_activating_002.png'
import activating_003 from './images/onboarding_activating_003.png'
import activating_004 from './images/onboarding_activating_004.png'
import activating_005 from './images/onboarding_activating_005.png'
import waiting_001 from './images/onboarding_waiting_001.png'
import waiting_002 from './images/onboarding_waiting_002.png'
import waiting_003 from './images/onboarding_waiting_003.png'
import waiting_004 from './images/onboarding_waiting_004.png'
import waiting_005 from './images/onboarding_waiting_005.png'
import './onboarding.scss'

export const Onboarding = ({ onboardingType }) => {
  const onboardingImages = {
    [ONBOARDING_TYPE.ACTIVATING]: [
      {
        src: activating_001,
        alt: onboardingImageAlt[ONBOARDING_TYPE.ACTIVATING][1],
        width: 1178,
        height: 558,
      },
      {
        src: activating_002,
        alt: onboardingImageAlt[ONBOARDING_TYPE.ACTIVATING][2],
        width: 1178,
        height: 558,
      },
      {
        src: activating_003,
        alt: onboardingImageAlt[ONBOARDING_TYPE.ACTIVATING][3],
        width: 1178,
        height: 559,
      },
      {
        src: activating_004,
        alt: onboardingImageAlt[ONBOARDING_TYPE.ACTIVATING][4],
        width: 1178,
        height: 558,
      },
      {
        src: activating_005,
        alt: onboardingImageAlt[ONBOARDING_TYPE.ACTIVATING][5],
        width: 1178,
        height: 558,
      },
    ],
    [ONBOARDING_TYPE.WAITING]: [
      {
        src: waiting_001,
        alt: onboardingImageAlt[ONBOARDING_TYPE.WAITING][1],
        width: 1178,
        height: 558,
      },
      {
        src: waiting_002,
        alt: onboardingImageAlt[ONBOARDING_TYPE.WAITING][2],
        width: 1179,
        height: 559,
      },
      {
        src: waiting_003,
        alt: onboardingImageAlt[ONBOARDING_TYPE.WAITING][3],
        width: 1179,
        height: 559,
      },
      {
        src: waiting_004,
        alt: onboardingImageAlt[ONBOARDING_TYPE.WAITING][4],
        width: 1180,
        height: 560,
      },
      {
        src: waiting_005,
        alt: onboardingImageAlt[ONBOARDING_TYPE.WAITING][5],
        width: 1179,
        height: 560,
      },
    ],
  }

  return (
    <section className="onboarding">
      {onboardingImages[onboardingType].map((image, i) => (
        <img key={i} {...image} alt={image.alt} />
      ))}
    </section>
  )
}
