export const summarizePatientAccesses = (patientAccesses) => {
  // return latest access for each unique clinician.
  const latestUniqueAccess = new Map()
  for (const access of patientAccesses) {
    if (latestUniqueAccess.has(access.clinicianId)) {
      continue
    }
    latestUniqueAccess.set(access.clinicianId, access)
  }

  const latestUniqueAccessArray = Array.from(latestUniqueAccess.values())

  return {
    summarizedAccesses: latestUniqueAccessArray.slice(0, 3),
    numberOfAdditionalClinicians: Math.max(
      latestUniqueAccessArray.length - 3,
      0,
    ),
  }
}
