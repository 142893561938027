import { useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { NotificationType } from '../Constants/enums'
import { SET_NOTIFICATION } from '../Constants/user'
import { UserContext } from '../context'
import { useLogout } from './useLogout'

const ENABLE_IDLE_LOGOUT = process.env.NODE_ENV !== 'development'
const IDLE_TIMEOUT_SECONDS = 15 * 60
const IDLE_WARNING_SECONDS = 2 * 60

const useIdle = () => {
  const { dispatch } = useContext(UserContext)
  const { handleLogout } = useLogout()

  const handleIdle = () => {
    if (!ENABLE_IDLE_LOGOUT) {
      return
    }
    handleLogout()
    dispatch({
      type: SET_NOTIFICATION,
      notification: NotificationType.IdleTimeoutLogout,
    })
  }

  const handlePrompt = () => {
    dispatch({
      type: SET_NOTIFICATION,
      notification: NotificationType.IdleTimeoutWarning,
    })
  }

  const { reset } = useIdleTimer({
    timeout: IDLE_TIMEOUT_SECONDS * 1000,
    promptBeforeIdle: IDLE_WARNING_SECONDS * 1000,
    onIdle: handleIdle,
    onPrompt: handlePrompt,
    stopOnIdle: true,
  })

  const resetIdleTimer = () => {
    reset()
  }

  return { resetIdleTimer }
}

export default useIdle
