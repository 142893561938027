import React, { useContext, useEffect, useState } from 'react'

import usePatientFilter from '../../hooks/usePatientFilter'

import { UserContext } from '../../context'
import { searchText } from '../../texts'
import { ButtonAsText } from '../ButtonAsText'
import './searchForm.scss'

const SearchForm = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFeedback, setSearchFeedback] = useState('')
  const [isShowingSearchResults, setIsShowingSearchResults] = useState(false)
  const [canShowSearchResults, setCanShowSearchResults] = useState(false)

  const {
    state: { searchResults },
  } = useContext(UserContext)

  const { preliminarySearchResults, showSearchResults, clearSearchResults } =
    usePatientFilter(searchTerm)

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
    if (!event.target.value) {
      clearSearchResults()
    }
  }

  const handleShowSearch = () => {
    showSearchResults()
  }

  const handleClearSearch = () => {
    setSearchTerm('')
    setSearchFeedback('')
    clearSearchResults()
  }

  const onSubmit = (event) => {
    event.preventDefault()
    if (canShowSearchResults) {
      setSearchTerm('')
      handleShowSearch()
    }
  }

  useEffect(() => {
    if (searchTerm) {
      setSearchFeedback(
        searchText.feedback
          .replace('{{numberOfMatches}}', preliminarySearchResults.length)
          .replace('{{searchTerm}}', searchTerm),
      )
    }
  }, [preliminarySearchResults, searchTerm])

  useEffect(() => {
    setIsShowingSearchResults(searchResults.length !== 0)
    setCanShowSearchResults(preliminarySearchResults.length !== 0)
  }, [preliminarySearchResults, searchResults])

  return (
    <form className="searchform" onSubmit={onSubmit}>
      <input
        type="search"
        placeholder={searchText.placeholder}
        value={searchTerm}
        onChange={handleChange}
      ></input>
      {(searchTerm || isShowingSearchResults) && (
        <p className="searchFeedback">
          <span>{searchFeedback}</span>
          {canShowSearchResults && searchTerm && (
            <ButtonAsText onClick={handleShowSearch} label={searchText.show} />
          )}{' '}
          {isShowingSearchResults && (
            <ButtonAsText
              onClick={handleClearSearch}
              label={searchText.clear}
            />
          )}
        </p>
      )}
    </form>
  )
}

export default SearchForm
