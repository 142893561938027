export const LOG_OUT = 'LOG_OUT'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USER_IS_LOGGED_IN = 'SET_USER_IS_LOGGED_IN'
export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT'
export const RESET_CURRENT_PATIENT_DATA = 'RESET_CURRENT_PATIENT_DATA'
export const SET_CURRENT_PATIENT_RESULTS = 'SET_CURRENT_PATIENT_RESULTS'
export const SET_CURRENT_PATIENT_ANSWERS = 'SET_CURRENT_PATIENT_ANSWERS'
export const SET_CURRENT_PATIENT_ACCESSES = 'SET_CURRENT_PATIENT_ACCESSES'
export const SET_PATIENTS = 'SET_PATIENTS'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_CLINIC_NAME = 'SET_CLINIC_NAME'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
