import React, { useContext } from 'react'

import { useLogout } from '../../hooks/useLogout'

import { Modal } from '.'
import { NotificationType } from '../../Constants/enums'
import { CLEAR_NOTIFICATION } from '../../Constants/user'
import { UserContext } from '../../context'
import { ButtonText, notificationText } from '../../texts'
import { ButtonStyle } from '../Button'

export const IdleWarning = (props) => {
  const { show, resetIdleTimer } = props

  const { dispatch } = useContext(UserContext)
  const { handleLogout } = useLogout()

  const handleStayLoggedIn = () => {
    resetIdleTimer()
    dispatch({ type: CLEAR_NOTIFICATION })
  }

  const handleCloseAndLogOut = () => {
    dispatch({ type: CLEAR_NOTIFICATION })
    handleLogout()
  }

  const stayButton = {
    style: ButtonStyle.Primary,
    label: ButtonText.stayLoggedIn,
    action: handleStayLoggedIn,
  }

  const logOutButton = {
    style: ButtonStyle.Secondary,
    label: ButtonText.logOut,
    action: handleCloseAndLogOut,
  }

  const buttons = [stayButton, logOutButton]

  return (
    <Modal
      show={show}
      handleClose={handleStayLoggedIn}
      title={notificationText[NotificationType.IdleTimeoutWarning].title}
      texts={notificationText[NotificationType.IdleTimeoutWarning].texts}
      buttons={buttons}
    />
  )
}
